import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image';
import './App.css';
import emidiaPreview from "./img/emidia-preview.png"; // Import the image

function App() {
  return (
    <Container className="App">
        <Image className="emidia" src={emidiaPreview} alt="greeting"/>
    </Container>
  );
}

export default App;
